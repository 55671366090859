
            @import 'src/styles/mixins.scss';
        

.description{
    h3{
        position: relative;
        color: #272361;
        @apply font-bold;
        &::before{
            content:"";
            position: absolute;
            height: 5px;
            width:115px;
            bottom: -10px;
            left:0;
            background-color: #ea7571;
        }
    }
}